import React, { useState, useEffect, useRef } from 'react';
import { signInWithGoogleForUsers, 
  changeCodeValue, 
  seeCodeValue, 
  addUserLoginTime, 
  // addUserLogoutTime, 
  setUpOrganizationCodeInFirebase, 
  whoIsOrganizationUserFinder, 
  whenIsMeetingDate, 
  endOrganizationCodeInFirebase, 
  checkMeetingHostingDevices, 
  getCodeValue,
  getAllExistingOrganizations} from "./firebase";
import './Stylesheets/App.css';
import Intro from "./components/Intro"
import UserInterface from "./components/UserInterface"
import OrganizationInterfaceCode from "./components/OrganizationInterfaceCode"
import LoginForOrganizationAccount from './components/LoginForOrganizationAccount';
import RegisterForOrganizationAccount from './components/RegisterForOrganizationAccount';
import OrganizationInterfaceIntro from './components/OrganizationInterfaceIntro';
import OrganizationInterfaceAnalytics from './components/OrganizationInterfaceAnalytics';
import {getAllUnstructuredData} from "./firebase";
import CreatableSelect from 'react-select/creatable'; 

function App() {
  const [intromemberstate, setIntroMemberState] = useState(true);
  const [timerseconds, setTimerSeconds] = useState(-1); 
  const [timersecondsForHostingMeeting, setTimerSecondsForHostingMeeting] = useState(-3);
  const [codeValue, setCodeValue] = useState(0); 
  const [GoogleLoginComplete, setGoogleLoginComplete] = useState(false); 
  const [progressionofLoginOfUser, setProgressionOfLoginOfUser] = useState(0); 
  const [userloggedout, setUserLogOut] = useState(false); 
  const [loginningIntoOrganization, setLogginningIntoOrganization] = useState(true);
  const [registeringIntoOrganization, setRegisteringIntoOrganization] = useState(false); 
  const [OrganizationInterfaceIntroAllowed, setOrganizationInterfaceIntroAllowed] = useState(false); 
  const [OrganizationInterfaceCodeAllowed, setOrganizationInterfaceCode] = useState(false);
  const [OrganizationInterfaceAnalyticsAllowed, setOrganizationInterfaceAnalytics] = useState(false); 
  const [maxMeetingLength, setMaxMeetingLength] = useState(-1);
  const [waitingToConnectToHostingUser, setWaitingToConnectToHostingUser] = useState(false); 
  const [allOrganizationData, setAllOrganizationData] = useState([]); 
  const [OrganizationMainScreen, setOrganizationMainScreen] = useState(false); 
  const [GoogleAuthenticationForOrganizationsFailure, setGoogleAuthenticationForOrganizationsFailure] = useState(false);
  const [chooseTypeOfMeetingSelectionState, setChooseTypeOfMeetingSelectionState] = useState(false);
  const [typeOfMeeting, setTypeOfMeeting] = useState(""); 
  const [typeOfOrganization, setTypeOfOrganization] = useState(""); 
  const timer = useRef(null);
  const timerForHostingMeeting = useRef(null);
  const timerForMeetingLength = useRef(null); 
  const [choicesForOrganizationType, setChoicesForOrganizationType] = useState([
  ])

  const choicesForMeetingType = [
    {label: "General Member Meeting", value :  "General Member Meeting"},
    {label: "Executive Member Meeting", value : "Executive Member Meeting"},
    {label: "Competition Team Meeting", value : "Competition Team Meeting"},
    {label: "Volunteering Event", value : "Volunteering Event"},
    {label: "Schoolwide Event", value : "Schoolwide Event"}, 
    {label: "Optional Meeting", value : "Optional Meeting"}, 
    {label: "Other", value : "Other"}
  ]

  const changeOrganizationInterfaceCodeAllowed = async () => {
    const organizationUser = sessionStorage.getItem("email");
    let continueToHostedMeeting = false; 
    if (await checkMeetingHostingDevices(organizationUser)){
      setChooseTypeOfMeetingSelectionState(!chooseTypeOfMeetingSelectionState); 
      setOrganizationInterfaceCode(!OrganizationInterfaceCodeAllowed);
      let temporaryCodeValue = await getCodeValue(organizationUser); 
      setWaitingToConnectToHostingUser(true);
      while(temporaryCodeValue == await getCodeValue(organizationUser)){
        continueToHostedMeeting = true; 
      }
      if(temporaryCodeValue != await getCodeValue(organizationUser) && continueToHostedMeeting){
        setTimeout(async () => {
          setCodeValue(await getCodeValue(organizationUser)); 
          setWaitingToConnectToHostingUser(false); 
          setTimerSecondsForHostingMeeting(10);
        }, 1)
      }
    } 
    else {
      await setUpOrganizationCodeInFirebase(organizationUser, typeOfMeeting, typeOfOrganization);
      await changeCodeValueAndPutNewCode(); 
      setMaxMeetingLength(120); 
      setOrganizationInterfaceCode(!OrganizationInterfaceCodeAllowed);
      setChooseTypeOfMeetingSelectionState(!chooseTypeOfMeetingSelectionState); 
      setTimerSeconds(10);  
    }
  }

  const changeIntroState1 = async () => {
    setOrganizationInterfaceIntroAllowed(!OrganizationInterfaceIntroAllowed);
    setUserLogOut(!userloggedout); 
  } 
  const changeIntroState2 = () => {
    setOrganizationInterfaceIntroAllowed(!OrganizationInterfaceIntroAllowed);
    setOrganizationMainScreen(!OrganizationMainScreen)
  }
  // const changeGoogleLoginCompleteState = () => {
  //   setGoogleLoginComplete(!GoogleLoginComplete); 
  // }
  const changeIntroMemberState = async () => {
    await signInWithGoogleForUsers(); 
    setGoogleLoginComplete(!GoogleLoginComplete); 
    setIntroMemberState(!intromemberstate); 
  }
  const handleChangeInAttemptOnPin = async (event) => {
    if(event.target.value != ""){
      setProgressionOfLoginOfUser(0.1);
    }
    else if(event.target.value == ""){
      setProgressionOfLoginOfUser(0);
    }
    if(await seeCodeValue(event.target.value)){
      const organizationuser = await whoIsOrganizationUserFinder(event.target.value); 
      const meetingDate = await whenIsMeetingDate(event.target.value); 
      event.target.value = ""; 
      setProgressionOfLoginOfUser(-1);
        try {
          if(await addUserLoginTime(sessionStorage.getItem("name"), organizationuser, meetingDate, sessionStorage.getItem("email"))){
            setProgressionOfLoginOfUser(1)
          }
          else {
            setProgressionOfLoginOfUser(-3)
          }
        }
        catch (error) {
          console.error(error);
          setProgressionOfLoginOfUser(-2); 
        }
      // else if(progressionOfLoginUserChecker == 1){
      //   setProgressionOfLoginOfUser(-1.1)
      //   await addUserLogoutTime(sessionStorage.getItem("name"), organizationuser, meetingDate, sessionStorage.getItem("email")); 
      //   setProgressionOfLoginOfUser(2); 
      // }
      // else {
      //   await addUserLogoutTime(sessionStorage.getItem("name"), organizationuser, meetingDate); 
      //   setProgressionOfLoginOfUser(3); 
      // }
    }
  }
  const changeCodeValueAndPutNewCode = async () => {
    const newCodeValue = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
    setCodeValue(newCodeValue);   
    await changeCodeValue(newCodeValue, sessionStorage.getItem("email")); 
  }

  const findCodeValueAndSetCodeValue = async () => {
    setCodeValue(await getCodeValue(sessionStorage.getItem("email"))); 
  }

  useEffect(() => {
    if(timerseconds == -1){
      clearTimeout(timer.current); 
    }
    else if(timerseconds < 1 && timerseconds !== -1){
      setTimerSeconds(10);
      changeCodeValueAndPutNewCode(); 
    }
    else if (timerseconds !== -1){
      timer.current = setTimeout(() => setTimerSeconds(timerseconds-1), 1000); 
    }
  }, [timerseconds]) 
  
  useEffect(() => {
    if(timersecondsForHostingMeeting == -1){
      clearTimeout(timerForHostingMeeting.current); 
    }
    else if(timersecondsForHostingMeeting < 1 && timersecondsForHostingMeeting !== -1){
      setTimerSecondsForHostingMeeting(10);
      findCodeValueAndSetCodeValue(); 
    }
    else if (timersecondsForHostingMeeting !== -1){
      timerForHostingMeeting.current = setTimeout(() => setTimerSecondsForHostingMeeting(timersecondsForHostingMeeting-1), 1000); 
    }
  }, [timersecondsForHostingMeeting]) 

  useEffect(() => {
    if(maxMeetingLength == -1){
      clearTimeout(timerForMeetingLength);
    }
    else if(maxMeetingLength != -1 && maxMeetingLength > 15){
      timerForMeetingLength.current = setTimeout(() => setMaxMeetingLength(maxMeetingLength-15), 900000)
    }
    else if(maxMeetingLength != -1 && maxMeetingLength <= 15){
      timerForMeetingLength.current = setTimeout(() => setMaxMeetingLength(maxMeetingLength-1), 60000)
    }
  }, [maxMeetingLength])

  const logout = async() => {
    // await signOut(auth);
    setUserLogOut(!userloggedout); 
    setOrganizationInterfaceIntroAllowed(!OrganizationInterfaceIntroAllowed);
  }


  const changeIntoOrganizationInterfaceAllowed = () => {
    setOrganizationInterfaceIntroAllowed(!OrganizationInterfaceIntroAllowed); 
    setLogginningIntoOrganization(!loginningIntoOrganization);
  }

  const changeIntoRegisterForOrganizationAccountPage = () => {
    setRegisteringIntoOrganization(!registeringIntoOrganization);
    setLogginningIntoOrganization(!loginningIntoOrganization);
  }

  const changeOrganizationInterfaceIntroAllowed = () => {
    setOrganizationInterfaceIntroAllowed(!OrganizationInterfaceIntroAllowed); 
  }

  const changeOrganizationInterfaceAnalyticsAllowed = async () => {
    const putOrganizationDataAsThis = await getAllUnstructuredData(sessionStorage.getItem("email")); 
    setAllOrganizationData(putOrganizationDataAsThis); 
    setOrganizationInterfaceAnalytics(!OrganizationInterfaceAnalyticsAllowed); 
    setOrganizationMainScreen(!OrganizationMainScreen);
  }

  const GoogleAuthenticationForOrganizations = async () => {
    const checker = await signInWithGoogleForUsers(); 
    if(checker == true){
      let possibleOrganizations = await getAllExistingOrganizations(sessionStorage.getItem("email"))
      let retrArray = [];
      if(possibleOrganizations !== undefined){
        possibleOrganizations.forEach((item) => {
          retrArray.push({label: `${item}`, value: `${item}`})
        })
      }
      setChoicesForOrganizationType(retrArray);
      changeOrganizationInterfaceIntroAllowed(); 
      changeIntoOrganizationInterfaceAllowed();
      setGoogleAuthenticationForOrganizationsFailure(false);
    }
    else if(checker != 2){
      setGoogleAuthenticationForOrganizationsFailure(true);
    }
  }
  const exitOrganizationCodePageFromHostingSite = async () => {
    setOrganizationInterfaceCode(!OrganizationInterfaceCodeAllowed);
    setTimerSecondsForHostingMeeting(-1);
    setOrganizationMainScreen(!OrganizationMainScreen);
  }
  const exitOrganizationCodePage = async () => {
    await endOrganizationCodeInFirebase(sessionStorage.getItem("email")); 
    setTimerSeconds(-1);
    setMaxMeetingLength(-1); 
    setOrganizationInterfaceCode(!OrganizationInterfaceCodeAllowed);
    setOrganizationMainScreen(!OrganizationMainScreen);
  }
  const logoutFromStudent = async () => {
    setProgressionOfLoginOfUser(0);
    await logout(); 
  }

  const goBackToSignIn = () => {
    setLogginningIntoOrganization(!loginningIntoOrganization);
    setOrganizationInterfaceIntroAllowed(!OrganizationInterfaceIntroAllowed);
  }

  const goBackToOrganizationInterfaceIntroAllowed = () => {
    setOrganizationMainScreen(!OrganizationMainScreen);
    setOrganizationInterfaceIntroAllowed(!OrganizationInterfaceIntroAllowed);
  }

  const goBacktoOrganizationMainScreenFromAnalytics = () => {
    setOrganizationMainScreen(!OrganizationMainScreen);
    setOrganizationInterfaceAnalytics(!OrganizationInterfaceAnalyticsAllowed);
  }

  const handleInputChangeOnChooseTypeOfMeeting = (inputValue) => {
    setTypeOfMeeting(inputValue.value)
  };

  const handleInputChangeOnChooseTypeOfOrganization = (inputValue) => {
    setTypeOfOrganization(inputValue.value);
  }

  const setChooseTypeOfMeetingSelectionStateFunction = () => {
    setChooseTypeOfMeetingSelectionState(!chooseTypeOfMeetingSelectionState); 
    setOrganizationMainScreen(!OrganizationMainScreen); 
    setTypeOfMeeting("")
  }

  return (
    <div className="App">
      { (loginningIntoOrganization) ? <LoginForOrganizationAccount changeIntoRegisterForOrganizationAccountPage={changeIntoRegisterForOrganizationAccountPage} changeIntoOrganizationInterfaceAllowed={changeIntoOrganizationInterfaceAllowed} changeOrganizationInterfaceIntroAllowed={changeOrganizationInterfaceIntroAllowed} GoogleAuthenticationForOrganizations={GoogleAuthenticationForOrganizations} GoogleAuthenticationForOrganizationsFailure={GoogleAuthenticationForOrganizationsFailure}/> : undefined} 
      { (registeringIntoOrganization) ? <RegisterForOrganizationAccount changeIntoRegisterForOrganizationAccountPage={changeIntoRegisterForOrganizationAccountPage} /> : undefined}
      { (OrganizationInterfaceIntroAllowed) ? <Intro change1={changeIntroState1} change2={changeIntroState2} goBackToSignIn={goBackToSignIn}/> : undefined }
      { (userloggedout) ? <UserInterface handleChangeInAttemptOnPin={handleChangeInAttemptOnPin} progressionofLoginOfUser={progressionofLoginOfUser} logoutFromStudent={logoutFromStudent}/> : undefined }
      { (OrganizationMainScreen) ? <OrganizationInterfaceIntro setChooseTypeOfMeetingSelectionStateFunction={setChooseTypeOfMeetingSelectionStateFunction} changeOrganizationInterfaceAnalyticsAllowed={changeOrganizationInterfaceAnalyticsAllowed} goBackToOrganizationInterfaceIntroAllowed={goBackToOrganizationInterfaceIntroAllowed}/> : undefined}
      { (chooseTypeOfMeetingSelectionState) ? <div className="OrganizationInterfaceCodeDisplay">
          <h2>Select what organization you are having your meeting for: </h2>
          <CreatableSelect placeholder={"Type in this box to create a new type of organization"} options={choicesForOrganizationType} onChange={handleInputChangeOnChooseTypeOfOrganization}/>
          <div style={{"marginTop": "2em"}}></div>
          <h2>Select which type of meeting you are having: </h2>
          <CreatableSelect placeholder={"Type in this box to create a new type of meeting"} options={choicesForMeetingType} onChange={handleInputChangeOnChooseTypeOfMeeting}/>
          <div style={{"marginTop": "20em"}}></div>
          <div className="centerForNow">
            {(typeOfMeeting != "" && typeOfOrganization != "" && typeOfMeeting != undefined) ? <button id="EndMeetingButton" onClick={changeOrganizationInterfaceCodeAllowed} className='button button--green button--swipe customButtonSmaller pushBack'><span className='button__text customButtonTextSmaller'>Submit</span></button> : undefined}
          </div>
          <div className="centerForNow">
            <button id="EndMeetingButton" onClick={setChooseTypeOfMeetingSelectionStateFunction} className='button button--red button--swipe customButtonSmaller pushBack'><span className='button__text customButtonTextSmaller'>Go Back</span></button>
          </div>
        </div> : undefined}
      {OrganizationInterfaceCodeAllowed ? <OrganizationInterfaceCode timerseconds={timerseconds} codeValue={codeValue} exitOrganizationCodePage={exitOrganizationCodePage} maxMeetingLength={maxMeetingLength} timersecondsForHostingMeeting={timersecondsForHostingMeeting} waitingToConnectToHostingUser={waitingToConnectToHostingUser} exitOrganizationCodePageFromHostingSite={exitOrganizationCodePageFromHostingSite}/> : undefined }
      {OrganizationInterfaceAnalyticsAllowed ? <OrganizationInterfaceAnalytics allData1={allOrganizationData}  goBacktoOrganizationMainScreenFromAnalytics={goBacktoOrganizationMainScreenFromAnalytics}/> : undefined } 
    </div>
  )
}
export default App;



//Use RAFCE to generate arrow function with default export
// Do NPM RUN BUILD and then Firebase deploy 

