const Intro = ({change1, change2, goBackToSignIn}) => {
    return (
    <div>
      <div className='containerCustom'>
        <button onClick={change2} className='button button--green button--swipe'>     
          <span className='button__text'>I am an organization</span>
        </button>
        <button onClick={change1} className='button button--green button--swipe'>     
          <span className='button__text'>I am a user</span>
        </button>
      </div>
      <div className="centerForNow">
          <button onClick={goBackToSignIn}id="EndMeetingButton" className='button button--red button--swipe customButtonSmaller'><span className='button__text customButtonTextSmaller'>Sign Into Another Account</span></button>
        </div>
    </div>
  )
}

export default Intro