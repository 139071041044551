import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup} from "firebase/auth"
import { getFirestore, collection, getDocs, updateDoc, getDoc, doc, setDoc, deleteDoc, arrayUnion} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDpbzWPJwMNLOpsDUG4Q2kxIysN-tO5tzE",
  authDomain: "bepresent-97f17.firebaseapp.com",
  projectId: "bepresent-97f17",
  storageBucket: "bepresent-97f17.appspot.com",
  messagingSenderId: "925933032461",
  appId: "1:925933032461:web:23ded6193e8a4b8bb23144",
  measurementId: "G-LCHN9GF05T"
};

// Initialize Firebase
export const appfirebase = initializeApp(firebaseConfig);
export const analytics = getAnalytics(appfirebase);
export const auth = getAuth(appfirebase); 

export const provider = new GoogleAuthProvider()

export const signInWithGoogleForUsers = async () => {
    let retrbool = 0;   
    await signInWithPopup(auth, provider).then((result) => {
        const username = result.user.displayName;
        const email = result.user.email;
        const profilePic = result.user.photoURL;
        sessionStorage.setItem("name", username);
        sessionStorage.setItem("email", email);
        sessionStorage.setItem("profilePic", profilePic); 
        retrbool = true;
    }).catch((error) => {
        retrbool = false;
        if("Firebase: Error (auth/cancelled-popup-request)." == error.message){
            retrbool = 2;
        }
    })
    return retrbool; 
}

// Deal with Database
const db = getFirestore(appfirebase); 

export const seeCodeValue = async (userValue) => {
    const querySnapshot = await getDocs(collection(db, "ACTIVE PINS"));
    let val = false; 
    querySnapshot.forEach((doc) => {
        if(userValue == doc.data().PIN){
            val = true;  
        }
    })
    return val; 
}

export const getCodeValue = async(OrganizationUserName) => {
    const specificActiveMeetingRef = doc(db, `ACTIVE PINS`, OrganizationUserName);
    const snapshot = await getDoc(specificActiveMeetingRef);
    if(snapshot.exists()){
        return snapshot.data().PIN; 
    }
    else {
        return false; 
    }
}

export const whoIsOrganizationUserFinder = async (userValue) => {
    const querySnapshot = await getDocs(collection(db, "ACTIVE PINS"));
    let docID = 0;
    querySnapshot.forEach((doc) => {
        if(userValue == doc.data().PIN){
            docID = doc.id;
        }
    })
    return docID; 
}

export const whenIsMeetingDate = async (userValue) => {
    const querySnapshot = await getDocs(collection(db, "ACTIVE PINS"));
    let meetingDateActual = 0;
    querySnapshot.forEach((doc) => {
        if(userValue == doc.data().PIN){
            meetingDateActual = doc.data().meetingDate;
        }
    })
    return meetingDateActual; 
}

export const changeCodeValue = async (newPIN, whoIsOrganizationUser) => {
    const specificActivePINRef = doc(db, "ACTIVE PINS", whoIsOrganizationUser)
    await updateDoc(specificActivePINRef, {
        "PIN" : newPIN
    }).catch((error) => {
        console.log("Error here");
        console.log(error);
    })
}

export const addUserLoginTime = async (UserUserName, OrganizationUserName, meetingDate, emailOfUser) => {
    let retrValue = true; 
    const specificActiveMeetingRef = doc(db, `Organizations/${OrganizationUserName}/${[meetingDate]}`, UserUserName); 
    const specificOrganizationMeetingList = doc(db, "Organizations", OrganizationUserName);
    const specificActivePINRef = doc(db, "ACTIVE PINS", OrganizationUserName); 
    const today = new Date();
    let meetingDateSpecifics = today.toLocaleString();
    meetingDateSpecifics = meetingDateSpecifics.replaceAll('/', '|');
    const snapshot = await getDoc(specificOrganizationMeetingList);
    if(!snapshot.exists() && meetingDate != 0){
        await setDoc(specificOrganizationMeetingList, {
            "Meetings" : arrayUnion(meetingDate), 
            "TypesOfMeetings" : arrayUnion(meetingDate.substring(meetingDate.indexOf("?")+2))
        })
    }
    else {
        const snapshotData = snapshot.data().Meetings; 
        if (!snapshotData.includes(meetingDate) && meetingDate != 0){
            if(!snapshot.data().TypesOfMeetings.includes(meetingDate.substring(meetingDate.indexOf("?")+2))){
                await updateDoc(specificOrganizationMeetingList, {
                    "Meetings" : arrayUnion(meetingDate),
                    "TypesOfMeetings" : arrayUnion(meetingDate.substring(meetingDate.indexOf("?")+2))
                })   
            }
            else {
                await updateDoc(specificOrganizationMeetingList, {
                    "Meetings" : arrayUnion(meetingDate)
                })
            }
        }
    }
    const snapshotOfSpecificActivePINRef = await getDoc(specificActivePINRef);
    console.log(snapshotOfSpecificActivePINRef.data())
    console.log(snapshotOfSpecificActivePINRef.data().Emails == undefined);
    let checkIfUserCheating; 
    if(snapshotOfSpecificActivePINRef.data().Emails == undefined){
        checkIfUserCheating = false; 
    }
    else {
        checkIfUserCheating = snapshotOfSpecificActivePINRef.data().Emails.includes(emailOfUser) 
        console.log(checkIfUserCheating);
    }
    if(meetingDate != 0 && !checkIfUserCheating){
        await setDoc(specificActiveMeetingRef, {
            LoggedIn: meetingDateSpecifics
        }).catch((err) => {
            console.log(err);
        })
        await updateDoc(specificActivePINRef, {
            "Emails" : arrayUnion(emailOfUser)
        }).catch((err) => {
            console.log(err);
        })
    }
    else if (checkIfUserCheating){
        retrValue = false;   
    }
    return retrValue; 
}

export const getAllExistingOrganizations = async (organizationName) => {
    let retrArray = [];
    const specificOrganizationMeetingList = doc(db, "Organizations", organizationName);
    const snapshot = await getDoc(specificOrganizationMeetingList);
    if(!snapshot.exists()){
        return retrArray;
    }
    else {
        retrArray = snapshot.data().TypesOfMeetings; 
    }
    return retrArray; 
}
// export const addUserLogoutTime = async (UserUserName, OrganizationUserName, meetingDate, emailofUser) => {
//     const specificActiveMeetingRef = doc(db, `Organizations/${OrganizationUserName}/${meetingDate}`, UserUserName); 
//     const today = new Date();
//     let meetingDateSpecifics = today.toLocaleString();
//     meetingDateSpecifics = await meetingDateSpecifics.replaceAll('/', '|')
//     await updateDoc(specificActiveMeetingRef, {
//         LoggedOut: meetingDateSpecifics
//     }, {merge: true}).catch((error) => {
//         console.log(error);
//     })
// }

export const setUpOrganizationCodeInFirebase = async (whoIsOrganizationUser, typeofMeeting, typeOfOrganization) => {
    const specificActivePINRef = doc(db, "ACTIVE PINS", whoIsOrganizationUser); 
    const today = new Date();
    let meetingDateSpecifics = today.toLocaleString();
    meetingDateSpecifics = await meetingDateSpecifics.replaceAll('/', '|') + " - " + typeofMeeting + " ? " + typeOfOrganization; 
    await setDoc(specificActivePINRef, {
        "meetingDate" : meetingDateSpecifics,
        "PIN" : Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000,
    }).catch((error) => {
        console.log(error);
    })
}

export const endOrganizationCodeInFirebase = async (whoIsOrganizationUser) => {
    await deleteDoc(doc(db, "ACTIVE PINS", whoIsOrganizationUser)); 
}

export const checkMeetingHostingDevices = async (OrganizationUserName) => {
    const specificActiveMeetingRef = doc(db, `ACTIVE PINS`, OrganizationUserName);
    const snapshot = await getDoc(specificActiveMeetingRef)
    if(snapshot.exists()){
        return true; 
    }
    else {
        return false; 
    }
}

export const getAllUnstructuredData = async (organizationName) => {
    const specificOrganizationMeetingList = doc(db, "Organizations", organizationName);
    const snapshot = await getDoc(specificOrganizationMeetingList);
    if(snapshot.exists()){
        const snapshotData = snapshot.data().Meetings; 
        let allUnstructuredDataArray = Array(snapshotData.length); 
        for(let index = 0; index<snapshotData.length; index++){
            const querySnapshot = await getDocs(collection(db, `Organizations/${organizationName}/${snapshot.data().Meetings[index]}`))
            allUnstructuredDataArray[index] = [];
            allUnstructuredDataArray[index].push(snapshot.data().Meetings[index]); 
            querySnapshot.forEach((doc) => {
                allUnstructuredDataArray[index].push(doc.id);
                allUnstructuredDataArray[index].push(doc.data().LoggedIn);
            })
        }
        return allUnstructuredDataArray; 
    }
    else {
        return ([]);
    }
}

export const deleteMeetingFromNumber = async (meetingDeleteValue, organizationName) => {
    const specificOrganizationMeetingList = doc(db, "Organizations", organizationName);
    const specificMeetingLocationSnapshot = await getDocs(collection(db, `Organizations/${organizationName}/${meetingDeleteValue}`)); 
    const MeetingListSnapshot = await getDoc(specificOrganizationMeetingList);
    let retrArray = MeetingListSnapshot.data().Meetings;
    retrArray = retrArray.filter(value => value != meetingDeleteValue);
    await updateDoc(specificOrganizationMeetingList, {
        "Meetings" : retrArray
    })
    specificMeetingLocationSnapshot.forEach((doc) => {
        deleteDoc(doc.ref);
    })
}