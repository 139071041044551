import { useState} from 'react';
import {signInWithEmailAndPassword} from "firebase/auth";
import { auth } from "../firebase"; 

const LoginForOrganizationAccount = ({changeIntoRegisterForOrganizationAccountPage, changeOrganizationInterfaceIntroAllowed, GoogleAuthenticationForOrganizations, changeIntoOrganizationInterfaceAllowed, GoogleAuthenticationForOrganizationsFailure}) => {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [failed, setFail] = useState(false); 
  
  const login = async () => {
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
      sessionStorage.setItem("name", loginEmail);
      sessionStorage.setItem("profilePic", "none")
      sessionStorage.setItem("email", loginEmail);
      changeOrganizationInterfaceIntroAllowed(); 
      changeIntoOrganizationInterfaceAllowed();
      setFail(false); 
      
    } catch (error) {
      console.log(error);
      setFail(true); 
    }
  };
  
  return (
    <div className="container" id="FixForMobile">
      <div className="row" id="expand">
        <div className="col-6 mx-auto p-4 m-5 border-light shadow-sm" style={{"backgroundColor": "#F7F7F7"}}>
          <h3 className="pb-3">Login to BePresent</h3>
          <div className="form-style">
            <div className="form-group pb-3">
              <input type="email" placeholder="Email" className="form-control" id="exampleInputEmail1" aria-describeby="emailHelp" onChange={(event) => {
                setLoginEmail(event.target.value);
                if(failed){
                  setFail(false); 
                }
              }}></input>
            </div>
            <div className="form-group pb-3">
              <input type="password" placeholder="Password" className="form-control" id="exampleInputPassword1" onChange={(event) => {
                  setLoginPassword(event.target.value);
                  if(failed){
                    setFail(false); 
                  }
                }}></input>
            </div>
            {failed ? <h6> Oops! There has been an error. Check your internet connection and please try again.</h6> : undefined}
            <div className="pb-2">
              <button type="submit" className="btn btn-dark w-100 font-weight-bold mt-2" onClick={login}>Login</button>
          </div>
          <div className="sideline">OR</div>
          <div>
            <button type="submit" className="btn btn-primary w-100 font-weight-bold mt-2" onClick={GoogleAuthenticationForOrganizations}><img className="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>Login With Google</button>
          </div>
          <div style={{"marginTop": "1em"}}></div>
          {GoogleAuthenticationForOrganizationsFailure ? <h6 style={{"text-align" : "center"}}> Oops! There has been an error with logging in with Google. Check your internet connection and please try again.</h6> : undefined}
          <div className="pt-3 text-center">
            Don't have an account? <a onClick ={changeIntoRegisterForOrganizationAccountPage} href="#">Sign Up</a>
          </div>
          </div>
          <div style={{"marginTop": "0.25em"}}></div>  
          <div className="pt-3 text-center">
            <h6><a href="https://bepresentinfo.notion.site/BePresent-8493a886cb894e8e9e5719026e274d58">Click here to learn more about what BePresent is</a></h6> 
          </div>
        </div>
      </div>
      <div>
      </div>
      <script src="https://use.fontawesome.com/f59bcd8580.js"></script>
    </div>
  )
}

export default LoginForOrganizationAccount