import React, { useState, useEffect } from 'react';
import backarrow from '../imgs/backarrow.png'; 
const UserInterface = ({handleChangeInAttemptOnPin, progressionofLoginOfUser, logoutFromStudent}) => {
  const [changeUserNameDisplayState, setUserNameDisplayState] = useState(false);
  const [changedNameValue, setChangedNameValue] = useState("");
  const [allowedToChangeName, setAllowedToChangeName] = useState(true);
  const changeUserNameDisplay = async () => {
    if (changedNameValue == ""){
      setUserNameDisplayState(!changeUserNameDisplayState); 
    }
    else {
      sessionStorage.setItem("name", changedNameValue);
      setChangedNameValue("");
      setUserNameDisplayState(!changeUserNameDisplayState);
    }
  }
  const handleChangeOnName = (event) => {
    setChangedNameValue(event.target.value); 
  }
  
  return (
    <div className="containerCustom2">
      {!changeUserNameDisplayState ?
      <div>
          <div>
            <h1>{sessionStorage.getItem("name")}</h1>
            <div style={{"marginTop": "1em"}}></div>
            {
              sessionStorage.getItem("profilePic") !== "none" ? <div>
                <img src={sessionStorage.getItem("profilePic")} id="userImage"></img>
                <div style={{"marginTop": "1em"}}></div>
              </div> : undefined 
            }
            {(progressionofLoginOfUser != 1 && progressionofLoginOfUser != -3 && progressionofLoginOfUser != -1) ? <h5>Enter Code to Login: </h5> : undefined}
            <div style={{"marginTop": "1em"}}></div>
          </div>
          {
            (progressionofLoginOfUser != 1 && progressionofLoginOfUser != -3 && progressionofLoginOfUser != -1) ?
          <div className="form-style">
            <input type="code" id="codeInputer" name="code" onChange={handleChangeInAttemptOnPin}/> 
          </div>
            : undefined
          } 
          <div style={{"marginTop": "1.25em"}}></div>
          <div className="closeInText">
          { (progressionofLoginOfUser == 0) ? <div> 
              <h6 style={{"fontsize": "1em"}}>Note: Make sure that <strong>{sessionStorage.getItem("name")}</strong> is the name you want to login with.</h6>
              </div> : undefined}
            { (progressionofLoginOfUser == 0.1 || progressionofLoginOfUser == 0.2) ? <h6 style={{"fontsize": "13px"}}>Once you have the correct code typed, wait patiently as your attendance request goes through. </h6> : undefined}
            { (progressionofLoginOfUser == -2) ? <h6 style={{"fontsize": "13px"}}>There has been an issue logging you in. Please try again. </h6> : undefined}
            { (progressionofLoginOfUser == -3) ? <h6 style={{"fontsize": "13px"}}> You cannot login to the meeting again. </h6> : undefined}
            { (progressionofLoginOfUser == -1) ? <h6 style={{"fontsize": "13px"}}>Please wait patiently as we log you in. If this message appears for longer than a few seconds, you might have to retry logging in.</h6> : undefined}
            { (progressionofLoginOfUser == 1) ? <h6 style={{"fontsize": "13px"}}>You have sucessfully logged into the meeting as <strong>{sessionStorage.getItem("name")}</strong></h6> : undefined}
          </div>
          <div style={{"marginTop": "-1em"}}></div>
          <div className="centerForNow">
            {(progressionofLoginOfUser == 0) ? <button id="EndMeetingButton" onClick={changeUserNameDisplay}className='button button--blue button--swipe customButtonSmaller'><span className='button__text customButtonTextEvenSmaller'>Choose Different name</span></button> : undefined }
            {(progressionofLoginOfUser == 0) ? <button onClick={logoutFromStudent} id="EndMeetingButton" className='button button--red button--swipe customButtonSmaller'><span className='button__text customButtonTextSmaller'>Back</span></button> : undefined }
            {(progressionofLoginOfUser == 1 || progressionofLoginOfUser == -3) ? <img style={{"cursor": "pointer"}}onClick={logoutFromStudent} src={backarrow} id="backarrow"/> : undefined }
          </div>
      </div>
      : <div>
        {allowedToChangeName ?
          <div>
            <h1>Change Name To: </h1>
          <div style={{"marginTop": "1em"}}></div>
          <div className="form-style">
              <input type="code" id="codeInputer" name="code" onChange={handleChangeOnName}/> 
            </div>
            <div className="centerForNow">
              <button onClick={changeUserNameDisplay} id="EndMeetingButton" className='button button--green button--swipe customButtonSmaller'><span className='button__text customButtonTextSmaller'>Change Name</span></button>
            </div>
            <div style={{"marginTop": "-2em"}}></div>
            <div className="centerForNow">
              <button onClick={changeUserNameDisplay} id="EndMeetingButton" className='button button--red button--swipe customButtonSmaller'><span className='button__text customButtonTextSmaller'>Go Back</span></button>
            </div>
          </div>
          : 
          undefined
        }
          </div>}
    </div>
  )
}

export default UserInterface