const OrganizationInterfaceIntro = ({setChooseTypeOfMeetingSelectionStateFunction, changeOrganizationInterfaceAnalyticsAllowed, goBackToOrganizationInterfaceIntroAllowed}) => {
  return (
    <div>
    <div className='containerCustom'>
        <button onClick={setChooseTypeOfMeetingSelectionStateFunction} className='button button--blue button--swipe'><span className='button__text'>Create/Host a Meeting</span></button>
        <button onClick={changeOrganizationInterfaceAnalyticsAllowed} className='button button--blue button--swipe'><span className='button__text'>View Analytics</span></button>
    </div>
      <div className="centerForNow">
        <button onClick={goBackToOrganizationInterfaceIntroAllowed} id="EndMeetingButton" className='button button--red button--swipe customButtonSmaller'><span className='button__text customButtonTextSmaller'>Go Back to Main Screen</span></button>
      </div>
    </div>
  )
}

export default OrganizationInterfaceIntro