import { useState} from 'react';
import {createUserWithEmailAndPassword} from "firebase/auth";
import { auth } from "../firebase"; 

const RegisterForOrganizationAccount = ({changeIntoRegisterForOrganizationAccountPage}) => {
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [sucessful, setSucess] = useState(false); 
  const [failed, setFail] = useState(false); 

  const register = async () => {
    try {
      const user = await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      );
      console.log(user);
      setSucess(true); 
    } catch (error) {
      console.log(error.message);
      setFail(true); 
    }
  };
  return (
    <div className="container" id="FixForMobile">
      <div className="row" id="expand">
        <div className="col-6 mx-auto p-4 m-5 border-light shadow-sm" style={{"background-color": "#F7F7F7"}}>
          <h3 className="pb-3">Register a BePresent Account</h3>
          <div className="form-style">
            <div className="form-group pb-3">
              <input type="email" placeholder="Email" class="form-control" id="exampleInputEmail1" aria-describeby="emailHelp" onChange={(event) => {
                  setRegisterEmail(event.target.value);
                  if(sucessful){
                    setSucess(false);
                  }
                  else if(failed){
                    setFail(false); 
                  }
                }}
                ></input>
            </div>
            <div class="form-group pb-3">
              <input type="password" placeholder="Password" class="form-control" id="exampleInputPassword1" onChange={(event) => {
                setRegisterPassword(event.target.value); 
                if(sucessful){
                  setSucess(false);
                }
                else if (failed){
                  setFail(false); 
                }
              }}></input>
            </div>
            {sucessful ? <h7>You have been sucessfully registered</h7> : undefined}
            {failed ? <h6>There has been an error in your registration. Please try again! This error might because you already have an account registered.</h6> : undefined}
            <div class="pb-2 mt-1">
              <button type="submit" class="btn btn-dark w-100 font-weight-bold mt-2" onClick={register}>Create Account</button>
            </div>
          <div class="pt-3 text-center">
            Sucessfully Created an Account? <a onClick ={changeIntoRegisterForOrganizationAccountPage} href="">Sign In</a>
          </div>
          </div>
        </div>
      </div>
      <div>
      </div>
      <script src="https://use.fontawesome.com/f59bcd8580.js"></script>
    </div>
    
  )
}

export default RegisterForOrganizationAccount
