import '../Stylesheets/App.css';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import * as XLSX from "xlsx"; 
import Select from 'react-select'; 


import React, { useState, useEffect } from 'react';
import { deleteMeetingFromNumber} from '../firebase'; 
import { arrayRemove } from 'firebase/firestore';
const OrganizationInterfaceAnalytics = ({goBacktoOrganizationMainScreenFromAnalytics, allData1}) => {
  const [viewAllDataUnstructuredBool, setviewAllDataUnstructuredBool] = useState(false); 
  const [viewSpecificLoggedInUsersData, setViewSpecificLoggedInUsersData] = useState(false);
  const [selectedDateStart, setselectedDateStart] = useState(0); 
  const [selectedDateEnd, setselectedDateEnd] = useState(0);
  const [meetingDeleteNumber, setMeetingDeleteNumber] = useState(0);
  const [deleteMeetingNumberSuccess, setDeleteMeetingNumberSuccess] = useState(0); 
  const [allData, setAllData] = useState(allData1); 
  const [typeOfMeeting, setTypeOfMeeting] = useState(""); 
  const [individualPersonDataAllowed, setIndividualPersonData] = useState(false);
  const [specificIndividualPerson, setSpecificIndividualPerson] = useState(); 
  const [choicesForMeetingType, setChoicesForMeetingType] = useState([
    {label: "All Meetings", value :  ""},
  ])
  const [choicesForOrganizationType, setChoicesForOrganizationType] = useState([
  ])
  const [typeOfOrganization, setTypeOfOrganization] = useState();
  const [viewDataForWhichOrganizationScreen, setViewDataForWhichOrganizationScreen] = useState(true);


  useEffect(() => {
    const retrArray = []; 
    const retrArray2 = []; 
    for(let i = 0; i<allData.length; i++){
      if(!retrArray.find(retrArray => retrArray.label === `${allData[i][0].substring(allData[i][0].indexOf("-") + 2, allData[i][0].indexOf("?")-1)}`)){
        retrArray.push({label: `${allData[i][0].substring((allData[i][0].indexOf("-") + 2), allData[i][0].indexOf("?")-1)}`, value: `${allData[i][0].substring((allData[i][0].indexOf("-") + 2), allData[i][0].indexOf("?")-1)}`})
      }
      if(!retrArray2.find(retrArray2 => retrArray2.label === `${allData[i][0].substring(allData[i][0].indexOf("?")+2)}`)){
        retrArray2.push({label: `${allData[i][0].substring(allData[i][0].indexOf("?")+2)}`, value: `${allData[i][0].substring(allData[i][0].indexOf("?")+2)}`})
      }
    }
    setChoicesForMeetingType([...choicesForMeetingType, ...retrArray]);
    setChoicesForOrganizationType([...retrArray2]); 
    setTypeOfOrganization(retrArray2[0].value)
  }, []) 

  const handleChangeOnMeetingNumber = (event) => {
    setMeetingDeleteNumber(event.target.value);
  }

  const setselectedDateStartFix = (date) => {
    if(date == undefined){
      setselectedDateStart("");
    }
    else {
      setselectedDateStart(date);
    }
  }

  const setselectedDateEndFix = (date) => {
    if(date == undefined){
      setselectedDateEnd("");
    }
    else {
      setselectedDateEnd(date);
    }
  }

  const exportDataToExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws_data = []; 
    let setRow = 0; 
    ws_data[setRow] = []; 
    ws_data[setRow][0] = `Meetings for ${typeOfOrganization}`
    setRow++;
    setRow++;
    for(let index = 0; index < allData.length; index++){
      if(allData[index][0].substring(allData[index][0].indexOf("?")+2) == typeOfOrganization){
        ws_data[setRow] = []; 
        ws_data[setRow][0] = `Meeting #${index+1}`
        let dateOfMeeting = allData[index][0].replaceAll('|', '/').substring(0, allData[index][0].indexOf("-")); 
        let typeOfMeeting = allData[index][0].substring(allData[index][0].indexOf("-") + 2, allData[index][0].indexOf("?")-1)
        setRow++;
        ws_data[setRow] = []
        ws_data[setRow][0] = `Date of Meeting: ${dateOfMeeting}`
        let numOfUsers = Math.floor(allData[index].length/2); 
        setRow++;
        ws_data[setRow] = []
        ws_data[setRow][0] = `Type of Meeting: ${typeOfMeeting}`
        setRow++;
        ws_data[setRow] = []
        ws_data[setRow][0] = `Number of Users who logged into this meeting: ${numOfUsers}`
        setRow++ 
        ws_data[setRow] = []
        ws_data[setRow][0] = `List of Users with Logged In Timings`
        let counter = 1; 
      for(let subindex = 1; subindex <allData[index].length; subindex++){
        if(subindex % 2 == 1){
          ws_data[setRow+counter] = []; 
          ws_data[setRow+counter][0] = allData[index][subindex]
        }
        else if(subindex % 2 == 0){
          ws_data[setRow+counter][1] = "Logged in at " + allData[index][subindex].replaceAll('|', '/')
          counter++; 
        }
      }
      setRow = setRow + counter + 1; 
      }
        
    }
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    
    ws['!cols'] =  [{ width: 50 }, { width: 50 }, { width: 50 } ];

    XLSX.utils.book_append_sheet(wb,ws,"AllData1");

    XLSX.writeFile(wb, `${sessionStorage.getItem("name")} - All Logged User Data.xlsx`);
  } 

  const deleteMeetingNumber = async () => {
      if(checkAmountOfMeetingsForSpecificOrganization() >= meetingDeleteNumber){
        await deleteMeetingFromNumber(allData[meetingDeleteNumber-1][0], sessionStorage.getItem("email")).catch((err) => {setDeleteMeetingNumberSuccess(1)})
        setAllData(allData.filter(value => value != allData[meetingDeleteNumber-1]))
        setDeleteMeetingNumberSuccess(2); 
      }
  }
  const changeviewAllDataUnstructured = () => {
    setDeleteMeetingNumberSuccess(0);
    setviewAllDataUnstructuredBool(!viewAllDataUnstructuredBool);
    setViewDataForWhichOrganizationScreen(true);
  } 
  const changeViewSpecificLoggedInUsersData = () => {
    setselectedDateEnd(0);
    setselectedDateStart(0);
    setTypeOfMeeting("")
    setViewSpecificLoggedInUsersData(!viewSpecificLoggedInUsersData); 
    setTypeOfOrganization(choicesForOrganizationType[0].value)
  }
  const displayAllDataUnstructured = () => {
    const data = [];
    let realMeetingIndex = 0
    for(let index = 0; index<allData.length;index++){
      if(allData[index][0].substring(allData[index][0].indexOf("?")+2) == typeOfOrganization){
        data.push(<div key={index}>
          <h2>Meeting #{realMeetingIndex+1}</h2>
          <h3>
            Time of Meeting: {allData[index][0].replaceAll('|', '/').substring(0, allData[index][0].indexOf("-"))}
          </h3>
          <h3>
            Type of Meeting: {allData[index][0].substring(allData[index][0].indexOf("-") + 2, allData[index][0].indexOf("?")-1)}
          </h3>
          <h4>Users with Logged In Times: </h4>
          {displayAllSubDataUnstructured(allData[index])}
        </div>)
        realMeetingIndex++
      }
    }
    return data; 
  }

  const displayAllSubDataUnstructured = (subdata) => {
    const returJSXSubData = []; 
    for(let subindex = 1; subindex<subdata.length; subindex++){
      if(subindex % 2 == 1){
        returJSXSubData.push(<h5 key={subindex}>
          {subdata[subindex].replaceAll('|', '/')}
        </h5>)
      }
      else if (subindex % 2 == 0) {
        returJSXSubData.push(<h6 key={subindex}> 
          Logged In At: {subdata[subindex].replaceAll('|', '/')}
        </h6>)
      }
    }
    return returJSXSubData;
  }

  const displayAllUserData = () => {
    const users = []; 
    let numberOfMeetings = 0; 
    for(let index = 0; index<allData.length; index++){
      if(((selectedDateStart == 0  || allData[index][0].substring(0, allData[index][0].indexOf(",")).replaceAll('|', '/') >= selectedDateStart.toLocaleString().substring(0, selectedDateStart.toLocaleString().indexOf(","))) && (selectedDateEnd == 0 || allData[index][0].substring(0, allData[index][0].indexOf(",")).replaceAll('|', '/') <= selectedDateEnd.toLocaleString().substring(0, selectedDateEnd.toLocaleString().indexOf(","))))){
        if(allData[index][0].substring(allData[index][0].indexOf("-") + 2, allData[index][0].indexOf("?")-1) == typeOfMeeting || typeOfMeeting==""){ 
          if(allData[index][0].substring(allData[index][0].indexOf("?")+2) == typeOfOrganization || typeOfOrganization==""){
            numberOfMeetings++; 
            for(let subindex = 1; subindex<allData[index].length; subindex++){
              if(subindex % 2 == 1){
                users.push(allData[index][subindex].toUpperCase())
              }
          }
          }
        }
      }
    }
    const userAndMeetingPercentage = [];
    const stateOfUsersCompared = [];
    for(let index = 0; index<users.length; index++){
      let count = 0;
      let compared = true; 
      for(let z = 0; z < stateOfUsersCompared.length; z++){
        if(stateOfUsersCompared[z] == users[index]){
          compared = false;
        }
      }
      if(compared){
        users.forEach((i) => {
          if(i == users[index]){
            count++
          }
        })
        stateOfUsersCompared.push(users[index]);
        userAndMeetingPercentage.push([users[index], (100 * count) / numberOfMeetings]); 
      }
    }
    const returJSXUserPercentageData = []; 
      for(let index = 0; index<userAndMeetingPercentage.length; index++){
          returJSXUserPercentageData.push(<h5><span className="urlImitator" onClick={() => {individualPersonDataPlease(userAndMeetingPercentage[index][0])}}>{userAndMeetingPercentage[index][0]}</span> has attended {userAndMeetingPercentage[index][1].toPrecision(4)}% of {typeOfMeeting == "" ? "all meeting" : typeOfMeeting}{typeOfMeeting == "Other" ? undefined : "s"} in {typeOfOrganization} {selectedDateStart != 0 ? `from ${selectedDateStart.toLocaleString().substring(0, selectedDateStart.toLocaleString().indexOf(","))}` : undefined} {selectedDateEnd != 0 && selectedDateStart != 0 ? ` to ${selectedDateEnd.toLocaleString().substring(0, selectedDateEnd.toLocaleString().indexOf(","))}` : undefined } {selectedDateEnd != 0 && selectedDateStart == 0 ? `up until ${selectedDateEnd.toLocaleString().substring(0, selectedDateEnd.toLocaleString().indexOf(","))}` : undefined }</h5>)
      }
    return returJSXUserPercentageData;
  }

  const handleInputChangeOnChooseTypeOfMeeting = (inputValue) => {
    setTypeOfMeeting(inputValue.value)
  };

  const handleInputChangeOnChooseTypeOfOrganization = (inputValue) => {
    setTypeOfOrganization(inputValue.value);
  }

  const individualPersonDataPlease = (person) => {
    setIndividualPersonData(true);
    setSpecificIndividualPerson(person); 
  }

  const leaveIndividualPersonDataPlease = () => {
    setIndividualPersonData(false); 
  }

  const displaySpecificPersonData = () => {
    const specificPersonData = []; 
    let numberOfMeetingsAttended = 0; 
    // console.log(allData[0].indexOf(specificIndividualPerson)+1)
    for(let index = 0; index<allData.length; index++){
      const allDataOfIndexCapitalized = allData[index].map(element => {
        return element.toUpperCase(); 
      })
      if(allDataOfIndexCapitalized.includes(specificIndividualPerson) && allData[index][0].substring(allData[index][0].indexOf("?")+2) == typeOfOrganization){
        specificPersonData.push(<h3>Meeting #{numberOfMeetingsAttended+1}</h3>)
        specificPersonData.push(<h4>Time of Meeting: {allData[index][0].replaceAll('|', '/').substring(0, allData[index][0].indexOf("-"))}</h4>)
        specificPersonData.push(<h4>Type of Meeting: {allData[index][0].substring(allData[index][0].indexOf("-") + 2, allData[index][0].indexOf("?")-1)}</h4>)
        specificPersonData.push(<h5>Logged In At: {allData[index][allDataOfIndexCapitalized.indexOf(specificIndividualPerson)+1].replaceAll('|', '/')}</h5>)
        numberOfMeetingsAttended++; 
      }
    }
    specificPersonData.unshift(<h2>Total Number of Meetings Attended: {numberOfMeetingsAttended}</h2>)
    return specificPersonData; 
  }

  const changeViewDataForWhichOrganizationScreen = () => {
    setViewDataForWhichOrganizationScreen(!viewDataForWhichOrganizationScreen)
  }

  const checkAmountOfMeetingsForSpecificOrganization = () => {
    return allData.filter(obj => {
      if(obj[0].substring(obj[0].indexOf("?")+2) === typeOfOrganization){
        return true 
      }
      return false 
    }).length
  }

  return (
    <div>
      <div className='containerCustom'>
      {!viewAllDataUnstructuredBool && !viewSpecificLoggedInUsersData ? <button className='button button--orange button--swipe' onClick = {changeviewAllDataUnstructured}><span className='button__text'>View All Data</span></button> : undefined}
      {!viewSpecificLoggedInUsersData && !viewAllDataUnstructuredBool ? <button className='button button--orange button--swipe' onClick = {changeViewSpecificLoggedInUsersData}><span className='button__text'>View Specific Data on each Logged User</span></button> : undefined}  
      {viewAllDataUnstructuredBool && !viewSpecificLoggedInUsersData ? <div>
        {viewDataForWhichOrganizationScreen ?
          <div className="OptimizedDataForMobile">
            <h5>Select which organization's data you are trying to access</h5>
            <Select options={choicesForOrganizationType} onChange={handleInputChangeOnChooseTypeOfOrganization}/>
          <div style={{"marginTop": "2em"}}></div>
          <div className="centerForNow">
            <button id="EndMeetingButton" onClick={changeViewDataForWhichOrganizationScreen} className='button button--green button--swipe customButtonSmaller pushBack'><span className='button__text customButtonTextSmaller'>Submit</span></button>
          </div>
          <div className="centerForNow">
            <button id="EndMeetingButton" onClick={changeviewAllDataUnstructured} className='button button--red button--swipe customButtonSmaller pushBack'><span className='button__text customButtonTextSmaller'>Go Back</span></button>
          </div>
          </div>
        : 
        <div className="OptimizedDataForMobile">
          <h4>Note: This will not show meetings that had no users who logged in for {typeOfOrganization}</h4>
          <h1>There {checkAmountOfMeetingsForSpecificOrganization() == 1 ? 'has' : 'have'} been {checkAmountOfMeetingsForSpecificOrganization()} meeting{checkAmountOfMeetingsForSpecificOrganization() == 1 ? undefined : 's'}</h1>
          {
            displayAllDataUnstructured()
          }
          <div style={{"marginTop": "5em"}}></div>
          <div>
            <h3>Want to Delete a Meeting? Type the Specific Meeting # you want to Delete Below!</h3>
            <div className="centerForNow">
              <div className="form-style">
                  <input type="code" id="codeInputer" name="code" onChange={handleChangeOnMeetingNumber}/> 
                </div>
            </div>
            <div style={{"marginTop": "1em"}}></div>
            <div className="centerForNow">
            {deleteMeetingNumberSuccess == 1 ? <h5>It is not possible to delete this meeting as it does not exist!</h5>: undefined} 
            {deleteMeetingNumberSuccess == 2 ? <h5>Meeting has been successfully deleted!</h5>: undefined} 
            </div>
            <div className="centerForNow">
              <button onClick={deleteMeetingNumber} id="EndMeetingButton" className='button button--green button--swipe customButtonSmaller'><span className='button__text customButtonTextSmaller'>Confirm Meeting # Delete</span></button>
            </div>
          </div>
          <div className="centerForNow">
          <button onClick={exportDataToExcel} className='button button--green button--swipe customButtonSmaller'><span className='button__text customButtonTextEvenSmaller'>Export Data to Excel</span></button>
            <button onClick={changeviewAllDataUnstructured} className='button button--red button--swipe customButtonSmaller'><span className='button__text customButtonTextEvenSmaller'>Go Back</span></button>
          </div>
        </div> }
      </div> : undefined}
      {viewSpecificLoggedInUsersData  && !viewAllDataUnstructuredBool? 
        <div className="OptimizedDataForMobile">
          {!individualPersonDataAllowed ? 
          <div>
            <h4>Note: This will not show meetings that had no users who logged in</h4>
            {
              displayAllUserData()
            }
            <div style={{"marginTop": "5em"}}></div>
            <div>
              <h3>Select A Specific Time Interval:</h3>
              <h4>Starting Date: </h4>
              <DatePicker selected={selectedDateStart} onChange={date => setselectedDateStartFix(date)} maxDate={new Date()}/>
              <h4>Ending Date: </h4>
              <DatePicker selected={selectedDateEnd} onChange={date => setselectedDateEndFix(date)} maxDate={new Date()} minDate={selectedDateStart}/>
            </div>
            <div style={{"marginTop": "2em"}}></div>
            <div>
              <h3>Select which specific type of Organization you want to look at</h3>
              <Select options={choicesForOrganizationType} onChange={handleInputChangeOnChooseTypeOfOrganization} defaultValue={choicesForOrganizationType[0]} />
              <div style={{"marginTop": "2em"}}></div>
              <h3>Select which specific type of Meeting you want to look at</h3>
              <Select options={choicesForMeetingType} onChange={handleInputChangeOnChooseTypeOfMeeting}/>
            </div>
            <div className="centerForNow">
              <button onClick={changeViewSpecificLoggedInUsersData} className='button button--red button--swipe customButtonSmaller'><span className='button__text customButtonTextSmaller'>Go Back</span></button>
            </div>
          </div>
          : 
          <div>
            <div className="centerForNow">
              <h1>All Attendance Data on {specificIndividualPerson} for {typeOfOrganization}</h1>
            </div>
            {
              displaySpecificPersonData()
            }
            <div className="centerForNow">
              <button onClick={leaveIndividualPersonDataPlease} className='button button--red button--swipe customButtonSmaller'><span className='button__text customButtonTextSmaller'>Go Back</span></button>
            </div>
          </div>
          }
        </div>

        : undefined }
       </div>
      {!viewAllDataUnstructuredBool && !viewSpecificLoggedInUsersData ? 
      <div className="centerForNow">
        <button onClick={() => {goBacktoOrganizationMainScreenFromAnalytics()}} id="EndMeetingButton" className='button button--red button--swipe customButtonSmaller'><span className='button__text customButtonTextSmaller'>Exit Analytics Page</span></button>
      </div> : undefined
      } 
    </div>
    
  )
}

export default OrganizationInterfaceAnalytics