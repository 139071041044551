import '../Stylesheets/App.css';
import goToWebsite from '../imgs/GoToWebsite.png'; 
const OrganizationInterfaceCode = ({timerseconds, codeValue, exitOrganizationCodePage, maxMeetingLength, timersecondsForHostingMeeting, waitingToConnectToHostingUser, exitOrganizationCodePageFromHostingSite}) => {
  if(maxMeetingLength == 0){
    exitOrganizationCodePage(); 
  }
  window.onbeforeunload = (event) => {
    if(timerseconds != -1){exitOrganizationCodePage()};
  }
  if(codeValue == false){
    exitOrganizationCodePageFromHostingSite(); 
  }
  return (
    <div> {!waitingToConnectToHostingUser ? 
      <div className="OrganizationInterfaceCodeDisplay">
        {(timerseconds != -1) ? <h1 id="timerwithseconds" className="centerForNow">You have {timerseconds} second{timerseconds == 1 ? undefined : 's'} before the code expires.</h1> : undefined } 
        {(timerseconds == -1) ? <h1 id="timerwithseconds" className="centerForNow">You have {timersecondsForHostingMeeting} seconds before the code expires.</h1> : undefined } 
        <h1 id="code" className="centerForNow">Enter in this Code: {codeValue}</h1>
        <div id="spacer-2"></div>
        <img src={goToWebsite} id="imgOfWebsiteLink"/>
        <div id="spacer-1"></div>
        {(maxMeetingLength == -1) ? <h5 className="centerForNow">This is a hosted meeting. The code is being generated from another device. Once the hosting device's meeting ends, this meeting will end as well. If for some reason, you only intending for this device to host the meeting and it is showing up as a hosted meeting, click the button that says end meeting for all devices. This will start a new meeting that should work.</h5> : undefined}
        {(maxMeetingLength <= 15 && timerseconds != -1) ? <h1 className="centerForNow">This meeting will end in {maxMeetingLength} minute{maxMeetingLength == 1 ? undefined : 's'} </h1> : undefined } 
        <div className="centerForNow pushALittleForward">
            {timerseconds != -1 ? <button onClick={exitOrganizationCodePage} id="EndMeetingButton" className='button button--red button--swipe customButtonSmaller'><span className='button__text customButtonTextSmaller'>End Meeting</span></button> : 
            <div>
              <button onClick={exitOrganizationCodePageFromHostingSite} id="EndMeetingButton" className='button button--red button--swipe customButtonSmaller'><span className='button__text customButtonTextSmaller hostingMeetingButtons'>End Hosted Meeting</span></button>
              <button onClick={exitOrganizationCodePage} id="EndMeetingButton" className='button button--red button--swipe customButtonSmaller'><span className='button__text customButtonTextSmaller hostingMeetingButtons'>End Meeting for all Devices</span></button>
            </div>} 
        </div>
      </div> : <div>
          <div className="OrganizationInterfaceCodeDisplay"> Wait to connet to hosted meeting. Give this action about 8-16 seconds to complete. If you did not want connect to another hosted meeting, and instead want to host your own, click the button below to end your connect to another hosting meeting and then start a new meeting.</div>
          <div className="centerForNow">
            <button onClick={exitOrganizationCodePage} className='button button--red button--swipe customButtonSmaller centerForNow'><span className='button__text customButtonTextSmaller hostingMeetingButtons'>End Meeting For All Devices/Connection to Host Meeting</span></button>
          </div>
        </div> }
    </div>
    
  )
}

export default OrganizationInterfaceCode